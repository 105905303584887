import { boot } from 'quasar/wrappers';
import axios from 'axios';
import { LocalStorage } from 'quasar';
import { APLICATIVO_VII, APLICATIVO_BONDS } from './consts';

const APP_8_PHP_FILE = 'BondsAdmCadastroApp8.php';
const APP_5_PHP_FILE = 'Vii/ViiAdmCadastroApp5.php';

const resolveClientAppFile = () => {
  const aplicativo = LocalStorage.getItem('aplicativo');

  if (aplicativo === APLICATIVO_VII.toString()) {
    return `/${APP_5_PHP_FILE}`;
  }

  return `/${APP_8_PHP_FILE}`;
};

const client = axios.create({
  baseURL: process.env.API_BASE_URL,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
});

const appendData = (data) => {
  const user = LocalStorage.getItem('user') || {};
  const app = LocalStorage.getItem('aplicativo') || APLICATIVO_BONDS;

  return new URLSearchParams({
    app,
    ...user,
    ...data,
  });
};

client.interceptors.request.use((config) => {
  const data = appendData(config.data);

  return {
    ...config,
    data,
  };
}, (error) => Promise.reject(error));

export default boot(({ app }) => {
  app.config.globalProperties.$axios = axios;
  app.config.globalProperties.$api = client;
});

export { axios, client, resolveClientAppFile };
