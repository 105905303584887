import { LocalStorage } from 'quasar';

const routes = [
  {
    path: '/',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: '',
        component: () => import('pages/Index.vue'),
        beforeEnter: (to, from, next) => {
          const user = LocalStorage.getItem('user');

          LocalStorage.remove('aplicativo');

          if (user !== null) {
            next({ path: '/painel' });
            return;
          }

          next();
        },
      },
      {
        path: '/painel',
        component: () => import('pages/Panel.vue'),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'aplicativo/:id',
        component: () => import('layouts/OnlyRouter.vue'),
        beforeEnter: (to, from, next) => {
          LocalStorage.set('aplicativo', to.params.id);

          next();
        },
        children: [
          {
            path: '',
            component: () => import('pages/List.vue'),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: 'adicionar',
            component: () => import('pages/Add.vue'),
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },

    ],
  },

  {
    path: '/:catchAll(.*)*',
    component: () => import('pages/Error404.vue'),
  },
];

export default routes;
