export const APLICATIVO_BONDS = 8;
export const APLICATIVO_VII = 5;

export const SIM_NAO_OPTIONS = [
  {
    label: 'Sim',
    value: 'S',
  },
  {
    label: 'Não',
    value: 'N',
  },
];

export const TABLE_COLUMNS = [
  {
    name: 'id',
    required: true,
    label: 'ID',
    align: 'left',
    field: (row) => row.id || row.id_user,
    sortable: true,
  },
  {
    name: 'nome',
    required: true,
    label: 'Nome',
    field: 'nome',
    align: 'left',
    sortable: true,
  },
  {
    name: 'telefone',
    align: 'center',
    label: 'Telefone',
    field: 'telefone',
    sortable: true,
  },
  {
    name: 'email',
    align: 'center',
    label: 'E-mail',
    field: 'email',
    sortable: true,
  },
  {
    name: 'id_up',
    align: 'center',
    label: 'ID do Patrocinador',
    field: 'id_up',
    sortable: true,
  },
  {
    sortable: false,
  },
];

export const FILTERS_REMOVED_BONDS = ['bloqueado', 'id_user'];

export const FILTERS_OPTIONS = [
  {
    label: 'ID Posicional',
    value: 'id',
  },
  {
    label: 'ID do Participante',
    value: 'id_user',
  },
  {
    label: 'ID do Patrocinador',
    value: 'id_up',
  },
  {
    label: 'Telefone',
    value: 'telefone',
  },
  {
    label: 'Nome',
    value: 'nome',
  },
  {
    label: 'Email',
    value: 'email',
  },
  {
    label: 'Bloqueado',
    value: 'bloqueado',
  },
];
